
import { defineStore } from "pinia";

export default defineStore('common', {
  state: () => {
    return {
      toastGlobalMessage: "",
      toastGlobalState: false
    }
  },
  getters: {
  },
  actions: {
  },
})
