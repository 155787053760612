<template>
    <div>
        <Toast position="bottom-right" group="br" />
    </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';

import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

import commonStore from "@/store"

const toast = useToast();
const store = commonStore()

watch(
  () => store.toastGlobalState,
  () => {
    show(store.toastGlobalMessage)
  }
)

function show(msg: string) {
  toast.add({ severity: 'error', summary: 'Ошибка', detail: msg || "Обратитесь в департамент разработки.", group: 'br', life: 5000 });
}
</script>