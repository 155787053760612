<template>
    <div class="handler-container">
        <div v-if="avaliable">
            <slot></slot>
        </div>
        <div v-else class="handler-access-denied">
            У вас нет доступа к ресурсу.
        </div>
    </div>  
</template>

<script setup lang="ts">

import { computed } from 'vue';

const props = defineProps<{
    errorCode?: number
}>()

const avaliable = computed(() => props.errorCode != 403)

</script>

<style scoped lang="scss">
.handler-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.handler-access-denied {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>