import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from "pinia";
import PrimeVue from 'primevue/config';
import PrimeVueLocale from './assets/settings/locale.json'
import ToastService from 'primevue/toastservice';
import ApiClientService from './services/api/ApiClientService'
import ErrorHandler from './services/utils/ErrorHandler';
import 'primevue/resources/themes/aura-light-purple/theme.css'


const pinia = createPinia();

createApp(App)
    .use(pinia)
    .use(router)
    .use(PrimeVue, {
        ripple: true,
        locale: PrimeVueLocale
    })
    .use(ToastService)
    .mount('#app');

export const rootGetters = {
    api: ApiClientService,
    errorHandler: new ErrorHandler()
};

