<template>
    Страница сегментов
    <div>
    <HandlerAccessDenied>
        <Button label="Войти" class="form-button" @click="logout"/>
    </HandlerAccessDenied>
    </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import { useAuthStore } from '@/store/auth/login';
import { useRouter } from 'vue-router'

import HandlerAccessDenied from '@/shared/base/HandlerAccessDenied.vue';

const authStore = useAuthStore()
const router = useRouter();

function logout() {
    authStore.logout()
    router.push({ name: 'login'});
}

</script>