<template>
    <div class="auth-form">
        <h1>Вход в систему</h1>
        <div class="new-user">
            <div>Новый пользователь?</div>
            <a href="#">Создать учетную запись</a>
        </div>
        <div class="auth-form-components">
            <div class="flex flex-col input-labeled">
                <label for="username">E-mail</label>
                <InputText id="username" v-model="crendetails.username" aria-describedby="username-help"
                    placeholder="Введите e-mail или номер телефона" :invalid="invalid"/>
            </div>
            <div class="flex flex-col input-labeled">
                <label for="password">Пароль</label>
                <Password  id="password" v-model="crendetails.password" placeholder="Введите пароль"
                    :feedback="false" :invalid="invalid"/>
            </div>
            <div class="bad-msg" v-if="invalid">Неправильный логин или пароль</div>
            <Button label="Войти" class="form-button" @click="login"/>
        </div>
        <div class="after-info">
            <div class="forgot"><a href="#">Забыли пароль?</a></div>
            <div class="policy">Нажимая на кнопку «Войти» вы принимаете 
                <router-link :to="{'name': 'privacyPolicy'}">условия пользовательского соглашения</router-link> и 
                <router-link :to="{'name': 'personalDataPolicy'}">разрешаете обработку персональных данных</router-link></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Password from 'primevue/password';

import { useRouter, useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth/login';

const authStore = useAuthStore()
const router = useRouter()
const crendetails = ref({
    username: "", 
    password: ""
});
const invalid = ref(false)

function login() {
    localStorage.removeItem("access_token")
    invalid.value = false;

    authStore
    .login(crendetails.value)
    .then(()=>{
        router.push({name: router.currentRoute.value.query.next?.toString() || "listSegments"})
    })
    .catch(() => {
        invalid.value = true;
    })
}

</script>

<style scoped lang="scss">
.auth-form {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 344px;
}

h1 {
    font-size: 1.71em;
    font-weight: 500;
    line-height: 130%;
}

.new-user {
    display: flex;
    font-size: 0.86em;
    font-weight: 500;
    line-height: 150%;

    div {
        margin-right: 10px;
    }

    a {
        color: $main-link-color;
    }
}

label {
    font-weight: 500;
    font-size: 1em;
    line-height: 160%;
    color: #BCBAC2;
    margin-bottom: 4px;
    padding-left: 5px;
}

.input-labeled {
    margin-top: 12px;
    width: 100%;
}

::v-deep(.p-inputtext.p-component) {
    width: 100%;
    font-size: 14px;
    color: $main-font-color;
    padding: 8px 12px;
}

::v-deep(.p-inputtext.p-component)::placeholder, 
input::placeholder {
    color: rgb(217, 217, 217);
}

.form-button {
    margin-top: 19px;
    width: 100%;
}

.bad-msg {
    font-size: 0.9em;
    font-weight: 400;
    line-height: 130%;
    margin-top: 10px;
    color: red;
}

.after-info {
    .forgot {
        font-weight: 500;
        margin-top: 6px;
        font-size: 0.86em;

        a {
            color: $main-link-color;
        }
    }

    .policy {
        font-size: 0.71em;
        font-weight: 400;
        line-height: 170%;
        margin-top: 10px;

        a {
            color: #A28FCE;
        }
    }
}
</style>